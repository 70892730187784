import React, { useMemo } from 'react';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import CheckboxDefault from '../../../atoms/CheckboxDefault';

import * as styles from './index.module.css';

const CheckboxWithLabelDefault = ({ label, block, ...props }) => {
  const rootClass = useMemo(() => {
    return [styles['root'], block && styles['block']].filter(Boolean).join(' ');
  }, [block]);

  const disabled = useMemo(() => !!props.disabled, [props.disabled]);

  return (
    <div className={rootClass}>
      <label
        className={!disabled ? styles['wrapper'] : styles['wrapperDisabled']}
      >
        <InlineBlockWrapper>
          <CheckboxDefault {...props} />
        </InlineBlockWrapper>
        <span className={styles['label']}>{label}</span>
      </label>
    </div>
  );
};

export default CheckboxWithLabelDefault;
