import React from 'react';

import * as styles from './index.module.css';

const CheckboxDefault = props => {
  return (
    <div className={styles['root']}>
      <input className={styles['input']} type="checkbox" {...props} />
      <span className={styles['checkbox']}></span>
    </div>
  );
};

export default CheckboxDefault;
