// extracted by mini-css-extract-plugin
export var confirmCheckboxWrapper = "index-module--confirm-checkbox-wrapper--TWPvg";
export var container = "index-module--container--8q5I9";
export var contentArea = "index-module--content-area--kGUvg";
export var heading = "index-module--heading--R2AJo";
export var headingMain = "index-module--heading-main--XRKXY";
export var headingWrapper = "index-module--heading-wrapper--JEZkJ";
export var isComingSoon = "index-module--is-coming-soon--Fvze-";
export var limitedContentList = "index-module--limited-content-list--GPhy-";
export var limitedContentListCard = "index-module--limited-content-list-card--oAnN4";
export var limitedContentListCardIconWrapper = "index-module--limited-content-list-card-icon-wrapper--MWeUM";
export var limitedContentListCardLabel = "index-module--limited-content-list-card-label--7ANCP";
export var limitedContentListItem = "index-module--limited-content-list-item--htZSY";
export var limitedContentsSection = "index-module--limited-contents-section--bTshc";
export var limitedContentsSectionHeading = "index-module--limited-contents-section-heading--LZN7j";
export var limitedContentsSectionHeadingWrapper = "index-module--limited-contents-section-heading-wrapper--NP7qP";
export var limitedContentsSectionWrapper = "index-module--limited-contents-section-wrapper--FJ9YC";
export var main = "index-module--main--XE0vr";
export var paragraph = "index-module--paragraph--IdZfL";
export var paragraphStrong = "index-module--paragraph-strong--ikOVK";
export var paragraphWrapper = "index-module--paragraph-wrapper---PQKL";
export var registrationStepsSection = "index-module--registration-steps-section--1NAC1";
export var registrationStepsSectionHeading = "index-module--registration-steps-section-heading--Hdpr+";
export var registrationStepsSectionHeadingWrapper = "index-module--registration-steps-section-heading-wrapper--XTLY3";
export var registrationStepsSectionWrapper = "index-module--registration-steps-section-wrapper--0MK6K";
export var registrationStepsWrapper = "index-module--registration-steps-wrapper--cpw71";
export var signUpButtonWrapper = "index-module--sign-up-button-wrapper--VBCFG";
export var subHeading = "index-module--sub-heading--M2Sze";
export var subHeadingMain = "index-module--sub-heading-main--FWKJ6";
export var subHeadingWrapper = "index-module--sub-heading-wrapper--Bf2gt";
export var termsOfServiceContainer = "index-module--terms-of-service-container--PuOVE";
export var termsOfServiceContainerWrapper = "index-module--terms-of-service-container-wrapper--gBibD";