import React from 'react';

import * as styles from './index.module.css';

const ButtonPrimaryLarge = props => {
  return (
    <div className={styles['root']}>
      <button className={styles['button']} {...props} />
    </div>
  );
};

export default ButtonPrimaryLarge;
