import React, { useState, useCallback } from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import { useAuth0 } from '../../contexts/Auth0Context';

import PublicRoot from '../../components/PublicRoot';
import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';

import TermsOfService from '../../molecules/common/TermsOfService';
import CheckboxWithLabelDefault from '../../molecules/common/CheckboxWithLabelDefault';
import RegistrationSteps from '../../molecules/common/RegistrationSteps';

import ButtonPrimaryLarge from '../../atoms/ButtonPrimaryLarge';

import { ReactComponent as RegisterNewsMail } from '../../images/register-news-mail.svg';
import { ReactComponent as RegisterLimitedCotents } from '../../images/register-limited-contents.svg';

import * as styles from './index.module.css';

const ConfirmTermOfServiceTemplate = ({ pageContext }) => {
  const { pagePath } = pageContext;

  const { signupWithRedirect } = useAuth0();

  const [isConfirmed, setIsConfirmed] = useState(false);

  const onConfirmCheckboxChange = useCallback(event => {
    const { checked } = event.target;

    setIsConfirmed(checked);
  }, []);

  const onSignUpButtonClick = useCallback(() => {
    signupWithRedirect();
  }, [signupWithRedirect]);

  return (
    <PublicRoot>
      <SEO path={pagePath} title="TORCH無料会員登録" />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <section>
              <div className={styles['container']}>
                <div className={styles['contentArea']}>
                  <div className={styles['headingWrapper']}>
                    <h1 className={styles['heading']}>
                      <span className={styles['headingMain']}>
                        TORCH無料会員登録
                      </span>
                    </h1>
                  </div>

                  <div className={styles['paragraphWrapper']}>
                    <p className={styles['paragraph']}>
                      TORCHでは会員登録いただいた方に、
                      <strong className={styles['paragraphStrong']}>
                        新着記事のお知らせメール
                      </strong>
                      をお送りしております。
                      <br />
                      ご希望の方は以下の利用規約をご確認いただき、ご同意のうえ、ページ下部の登録ボタンより会員登録画面にお進みください。今後はさらに、会員様向けコンテンツを拡充していく予定です。
                    </p>
                  </div>

                  <div className={styles['limitedContentsSectionWrapper']}>
                    <section className={styles['limitedContentsSection']}>
                      <InlineBlockWrapper
                        className={
                          styles['limitedContentsSectionHeadingWrapper']
                        }
                      >
                        <h2 className={styles['limitedContentsSectionHeading']}>
                          会員様限定コンテンツ
                        </h2>
                      </InlineBlockWrapper>

                      <div className={styles['limitedContentListWrapper']}>
                        <ul className={styles['limitedContentList']}>
                          <li className={styles['limitedContentListItem']}>
                            <div className={styles['limitedContentListCard']}>
                              <InlineBlockWrapper
                                className={
                                  styles['limitedContentListCardIconWrapper']
                                }
                              >
                                <RegisterNewsMail />
                              </InlineBlockWrapper>
                              <InlineBlockWrapper
                                className={
                                  styles['limitedContentListCardLabelWrapper']
                                }
                              >
                                <span
                                  className={
                                    styles['limitedContentListCardLabel']
                                  }
                                >
                                  新着記事メール通知
                                </span>
                              </InlineBlockWrapper>
                            </div>
                          </li>
                          <li className={styles['limitedContentListItem']}>
                            <div
                              className={`${styles['limitedContentListCard']} ${styles['isComingSoon']}`}
                            >
                              <InlineBlockWrapper
                                className={
                                  styles['limitedContentListCardIconWrapper']
                                }
                              >
                                <RegisterLimitedCotents />
                              </InlineBlockWrapper>
                              <InlineBlockWrapper
                                className={
                                  styles['limitedContentListCardLabelWrapper']
                                }
                              >
                                <span
                                  className={
                                    styles['limitedContentListCardLabel']
                                  }
                                >
                                  会員様限定コンテンツ
                                </span>
                              </InlineBlockWrapper>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>

                  <div className={styles['registrationStepsSectionWrapper']}>
                    <section className={styles['registrationStepsSection']}>
                      <div
                        className={
                          styles['registrationStepsSectionHeadingWrapper']
                        }
                      >
                        <h2
                          className={styles['registrationStepsSectionHeading']}
                        >
                          無料会員登録3ステップ
                        </h2>
                      </div>

                      <div className={styles['registrationStepsWrapper']}>
                        <RegistrationSteps currentStep={1} />
                      </div>
                    </section>
                  </div>

                  <div className={styles['subHeadingWrapper']}>
                    <h2 className={styles['subHeading']}>
                      <span className={styles['subHeadingMain']}>
                        利用規約の確認
                      </span>
                    </h2>
                  </div>

                  <div className={styles['termsOfServiceContainerWrapper']}>
                    <div className={styles['termsOfServiceContainer']}>
                      <TermsOfService />
                    </div>
                  </div>

                  <InlineBlockWrapper
                    className={styles['confirmCheckboxWrapper']}
                  >
                    <CheckboxWithLabelDefault
                      label="利用規約に同意する"
                      onChange={onConfirmCheckboxChange}
                    />
                  </InlineBlockWrapper>

                  <InlineBlockWrapper className={styles['signUpButtonWrapper']}>
                    <ButtonPrimaryLarge
                      onClick={onSignUpButtonClick}
                      disabled={!isConfirmed}
                    >
                      無料会員登録
                    </ButtonPrimaryLarge>
                  </InlineBlockWrapper>
                </div>
              </div>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </PublicRoot>
  );
};

export default ConfirmTermOfServiceTemplate;
